import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7dca815e = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _4052f92c = () => interopDefault(import('../pages/checkout/Cart.vue' /* webpackChunkName: "" */))
const _7f8e0f51 = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _092e1520 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _53341362 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _ec8d83ba = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _2f790f20 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _4ad76044 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _7ddcce4c = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _f4d8f7c4 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _891559ac = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _798bba20 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _098e5fd6 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _295abd82 = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _9bd0969a = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _6407be31 = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _22a40051 = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _7c3701f1 = () => interopDefault(import('../_templates/pages/payment-svea/Checkout.vue' /* webpackChunkName: "" */))
const _d874eace = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _2b251b74 = () => interopDefault(import('../_templates/pages/payment-svea/CheckoutConfirmation.vue' /* webpackChunkName: "" */))
const _f99530de = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _10ffccc8 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _2f69527b = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _024cca63 = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt",
    component: _7dca815e,
    name: "blog___nb_NO___default"
  }, {
    path: "/cart",
    component: _4052f92c,
    name: "checkout-cart___nb_NO___default"
  }, {
    path: "/checkout",
    component: _7f8e0f51,
    name: "checkout___nb_NO___default",
    children: [{
      path: "bestilling",
      component: _092e1520,
      name: "checkout-order___nb_NO___default"
    }, {
      path: "konto",
      component: _53341362,
      name: "checkout-account___nb_NO___default"
    }, {
      path: "takk",
      component: _ec8d83ba,
      name: "checkout-thank-you___nb_NO___default"
    }]
  }, {
    path: "/customer",
    component: _2f790f20,
    meta: {"titleLabel":"Account"},
    name: "customer___nb_NO___default",
    children: [{
      path: "addresses-details",
      component: _4ad76044,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___nb_NO___default"
    }, {
      path: "my-profile",
      component: _7ddcce4c,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___nb_NO___default"
    }, {
      path: "order-history",
      component: _f4d8f7c4,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___nb_NO___default"
    }, {
      path: "addresses-details/create",
      component: _891559ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___nb_NO___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _798bba20,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___nb_NO___default"
    }, {
      path: "order-history/:orderId",
      component: _098e5fd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___nb_NO___default"
    }]
  }, {
    path: "/faq.html",
    component: _295abd82,
    name: "faq___nb_NO___default"
  }, {
    path: "/nb_NO",
    component: _9bd0969a,
    name: "home___nb_NO"
  }, {
    path: "/onskeliste",
    component: _6407be31,
    name: "wishlist___nb_NO___default"
  }, {
    path: "/search",
    component: _22a40051,
    name: "search___nb_NO___default"
  }, {
    path: "/sveacheckout",
    component: _7c3701f1,
    name: "sveaCheckout___nb_NO___default"
  }, {
    path: "/checkout/Cart",
    component: _4052f92c,
    name: "checkout-Cart___nb_NO___default"
  }, {
    path: "/customer/reset-password",
    component: _d874eace,
    alias: "/customer/account/createPassword",
    name: "reset-password___nb_NO___default"
  }, {
    path: "/nb_NO/aktuelt",
    component: _7dca815e,
    name: "blog___nb_NO"
  }, {
    path: "/nb_NO/cart",
    component: _4052f92c,
    name: "checkout-cart___nb_NO"
  }, {
    path: "/nb_NO/checkout",
    component: _7f8e0f51,
    name: "checkout___nb_NO",
    children: [{
      path: "bestilling",
      component: _092e1520,
      name: "checkout-order___nb_NO"
    }, {
      path: "konto",
      component: _53341362,
      name: "checkout-account___nb_NO"
    }, {
      path: "takk",
      component: _ec8d83ba,
      name: "checkout-thank-you___nb_NO"
    }]
  }, {
    path: "/nb_NO/customer",
    component: _2f790f20,
    meta: {"titleLabel":"Account"},
    name: "customer___nb_NO",
    children: [{
      path: "addresses-details",
      component: _4ad76044,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___nb_NO"
    }, {
      path: "my-profile",
      component: _7ddcce4c,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___nb_NO"
    }, {
      path: "order-history",
      component: _f4d8f7c4,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___nb_NO"
    }, {
      path: "addresses-details/create",
      component: _891559ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___nb_NO"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _798bba20,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___nb_NO"
    }, {
      path: "order-history/:orderId",
      component: _098e5fd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___nb_NO"
    }]
  }, {
    path: "/nb_NO/faq.html",
    component: _295abd82,
    name: "faq___nb_NO"
  }, {
    path: "/nb_NO/onskeliste",
    component: _6407be31,
    name: "wishlist___nb_NO"
  }, {
    path: "/nb_NO/search",
    component: _22a40051,
    name: "search___nb_NO"
  }, {
    path: "/nb_NO/sveacheckout",
    component: _7c3701f1,
    name: "sveaCheckout___nb_NO"
  }, {
    path: "/sveacheckout/confirmation",
    component: _2b251b74,
    name: "sveaCheckoutConfirmation___nb_NO___default"
  }, {
    path: "/nb_NO/checkout/Cart",
    component: _4052f92c,
    name: "checkout-Cart___nb_NO"
  }, {
    path: "/nb_NO/customer/reset-password",
    component: _d874eace,
    alias: "/customer/account/createPassword",
    name: "reset-password___nb_NO"
  }, {
    path: "/nb_NO/sveacheckout/confirmation",
    component: _2b251b74,
    name: "sveaCheckoutConfirmation___nb_NO"
  }, {
    path: "/nb_NO/aktuelt/:slug+",
    component: _f99530de,
    name: "blogPost___nb_NO"
  }, {
    path: "/nb_NO/collection/:slug([^/.]+).html",
    component: _10ffccc8,
    name: "attributeCollection___nb_NO"
  }, {
    path: "/nb_NO/collection/:collection_slug/:slug+.html",
    component: _2f69527b,
    name: "attributeCollectionItem___nb_NO"
  }, {
    path: "/aktuelt/:slug+",
    component: _f99530de,
    name: "blogPost___nb_NO___default"
  }, {
    path: "/nb_NO/:slug+",
    component: _024cca63,
    name: "page___nb_NO"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _10ffccc8,
    name: "attributeCollection___nb_NO___default"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _2f69527b,
    name: "attributeCollectionItem___nb_NO___default"
  }, {
    path: "/",
    component: _9bd0969a,
    name: "home___nb_NO___default"
  }, {
    path: "/:slug+",
    component: _024cca63,
    name: "page___nb_NO___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
