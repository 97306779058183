const moduleOptions = `{"showNewsletter":false,"newsletterChecked":false}`;
var plugin_default = (context, inject) => {
  var _a;
  const options = JSON.parse(moduleOptions);
  let wm = (_a = context.$wm) != null ? _a : {};
  wm.paymentSvea = options;
  inject("wm", wm);
};
export {
  plugin_default as default
};
